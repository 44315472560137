import React from "react"

import Footer from "./footer"
import Header from "./header"
import "../styles/index.scss"
import layoutStyles from "./layout.module.scss"

const Layout = props => {
  return (
    <div className={layoutStyles.containerWrapper}>
      <div className={layoutStyles.content}>
        <Header />
        <div className="container">
          <main>{props.children}</main>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
