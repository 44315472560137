import React, { useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import headerStyles from "./header.module.scss"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    const burger = document.querySelector("#burger")
    const menu = document.querySelector("#menu")

    burger.addEventListener("click", e => {
      e.preventDefault()
      if (menu.classList.contains("hidden-small")) {
        menu.classList.remove("hidden-small")
        burger.innerHTML = "CLOSE"
      } else {
        menu.classList.add("hidden-small")
        burger.innerHTML = "MENU"
      }
    })
  }, [])

  return (
    <header className={headerStyles.header}>
      <div className="container">
        <div className={headerStyles.navbar}>
          <div className={headerStyles.navbarBrand}>
            <Link to="/" className={headerStyles.brand}>
              {data.site.siteMetadata.title}
            </Link>
            <button className={headerStyles.burger} id="burger">
              MENU
            </button>
          </div>
          <div className={headerStyles.navbarMenu}>
            <nav className="hidden-small" id="menu">
              <ul className={headerStyles.navList}>
                <li>
                  <Link
                    to="/"
                    className={headerStyles.navItem}
                    activeClassName={headerStyles.navItemActive}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className={headerStyles.navItem}
                    activeClassName={headerStyles.navItemActive}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog"
                    className={headerStyles.navItem}
                    activeClassName={headerStyles.navItemActive}
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className={headerStyles.navItem}
                    activeClassName={headerStyles.navItemActive}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
