import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import footerStyles from "./footer.module.scss"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          technologies
        }
      }
    }
  `)

  return (
    <footer className={footerStyles.footer}>
      <div className="container">
        <div className={footerStyles.innerFooter}>
          <div>Created by {data.site.siteMetadata.author} &copy; 2021</div>
          <div className={footerStyles.keywords}>
            Technologies:
            {data.site.siteMetadata.technologies.map(technology => (
              <span key={technology}>{technology}</span>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
